<template>
  <vue-good-table
    :columns="columns"
    :pagination-options="{
      enabled: true,
      perPage: pageLength
    }"
    :rows="filteredPrograms"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm
    }"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span v-if="props.column.field === 'title'">
        {{ props.row.title }}
      </span>
      <span v-else-if="props.column.field === 'begin_date'">
        {{ formatDate(props.row.begin_date) }}

      </span>
      <span v-else-if="props.column.field === 'end_date'">
        {{ props.row.end_date ? formatDate(props.row.end_date) : '-' }}
      </span>
      <span
        v-else-if="props.column.field === 'action'"
        class="d-flex"
      >
        <div
          v-if="tab === 'invitation'"
          class="item-options text-center d-flex"
        >
          <b-link
            class="btn btn-wishlist btn-light rounded mr-1"
            variant="light"
            @click="showProgramDetails(props.row)"
          >
            <span>View Details</span>
          </b-link>
          <b-button
            v-if="props.row.users_associationtables[0].status === 'Accept'"
            :disabled="true"
            class="btn btn-success btn-cart rounded ml-1"
          >
            <span>Accepted</span>
          </b-button>
          <b-button
            v-else-if="props.row.users_associationtables[0].status === 'Reject'"
            :disabled="true"
            class="btn btn-danger btn-cart rounded ml-1"
          >
            <span>Rejected</span>
          </b-button>
          <b-link
            v-else
            class="btn btn-primary btn-cart rounded ml-1"
            @click="showRespondModal(props.index, props.row.id)"
          >
            <span>Respond</span>
          </b-link>
        </div>
        <div
          v-else
          class="item-options text-center d-flex"
        >
          <b-link
            v-if="props.row.programs_partnerstables && props.row.programs_partnerstables.length > 0"
            :to="{ name: `mentor-${opportunitiesType}-rounds`, params: { id: props.row.id, pid: props.row.programs_partnerstables[0].id } }"
            class="btn btn-wishlist btn-light rounded mr-1"
            variant="light"
          >
            <span>Applications</span>
          </b-link>
          <b-link
            class="btn btn-wishlist btn-light rounded mr-1"
            variant="light"
            v-b-tooltip.hover title="View program details"
            @click="showProgramDetails(props.row)"
          >
            <span>View Details</span>
          </b-link>
          <b-link
            v-if="props.row.programs_partnerstables && props.row.programs_partnerstables.length > 0"
            :to="{ name: `mentor-${opportunitiesType}-select`, params: { id: props.row.id, pid: props.row.programs_partnerstables[0].id } }"
            class="btn btn-primary btn-cart rounded ml-1"
          >
            <span>Open</span>
          </b-link>
        </div>
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap">Showing 1 to</span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="mx-1"
            @input="(value) => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap">of {{ props.total }} entries</span>
        </div>
        <div>
          <b-pagination
            :per-page="pageLength"
            :total-rows="props.total"
            :value="1"
            class="mt-1 mb-0"
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item"
            @input="(value) => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import {
  BButton, BFormSelect, BLink, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import { formatDate } from '@/@core/utils/utils'

export default {
  components: {
    BButton,
    BLink,
    BFormSelect,
    BPagination,
    VueGoodTable,
  },
  props: {
    filteredPrograms: {
      type: Array,
      required: true,
    },
    opportunitiesType: {
      type: String,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
      default: 'invitation',
    },
    showProgramDetails: {
      type: Function,
      default: () => {
      },
    },
    showRespondModal: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      pageLength: 25,
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Begin Date',
          field: 'begin_date',
        },
        {
          label: 'End Date',
          field: 'end_date',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    formatDate,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss'
</style>
