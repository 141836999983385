<template>
  <b-modal
    id="view-details-modal"
    :title="modalDetails ? modalDetails.title : ''"
    hide-footer
    size="lg"
  >
    <Promised
      v-if="modalDetails && modalDetails.users_organizationtable && modalDetails.users_organizationtable.logo"
      :promise="getLinkFromS3(modalDetails.users_organizationtable.logo)"
    >
      <template #pending>
        <b-card-img
          class="card-img"
          src="https://picsum.photos/600/300"
        />
      </template>
      <template #default="data">
        <b-aspect
          :style="`background: url(${data}) center center / contain no-repeat`"
          aspect="2"
        />
      </template>
      <template #rejected>
        <b-card-img
          class="card-img"
          src="https://picsum.photos/600/300"
        />
      </template>
    </Promised>
    <b-card-img
      v-else
      class="card-img w-100"
      src="https://picsum.photos/600/300"
    />
    <b-table
      :items="modalDetailsItems"
      bordered
      stacked
    >
      <template #cell(Deliverables)="data">
        <ul class="list-unstyled">
          <li
            v-for="(deliverable, index) in data.item.Deliverables.split('\n')"
            :key="index"
          >
            {{ deliverable }}
          </li>
        </ul>
      </template>
      <template #cell(Criteria)="data">
        <ul class="list-unstyled">
          <li
            v-for="(criteria, index) in data.item['Criteria'].split('\n')"
            :key="index"
          >
            {{ criteria }}
          </li>
        </ul>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import {
  BAspect, BCardImg, BModal, BTable,
} from 'bootstrap-vue'
import { Promised } from 'vue-promised'
import { formatDate } from '@/@core/utils/utils'

export default {
  components: {
    BModal,
    BCardImg,
    BAspect,
    BTable,
    Promised,
  },
  props: {
    modalDetails: {
      type: Object,
      required: true,
      default: () => ({}),
      // Ensure the modalDetails object has the required properties
      validator: value => value.title && value.users_organizationtable
      ,
    },
  },
  computed: {
    modalDetailsItems() {
      return [{
        Title: this.modalDetails ? this.modalDetails.title : '',
        Type: this.modalDetails ? this.modalDetails.type : '',
        'Organized By': this.modalDetails ? this.modalDetails.users_organizationtable.title : '',
        Description: this.modalDetails ? this.modalDetails.description : '',
        Duration: this.modalDetails ? this.modalDetails.duration : '',
        'Start Date': formatDate(this.modalDetails ? this.modalDetails.begin_date : ''),
        Capacity: this.modalDetails ? this.modalDetails.capacity : '',
        Status: this.modalDetails ? this.modalDetails.status : '',
        Industry: this.modalDetails ? this.modalDetails.industry : '',
        'Lifecycle Stage': this.modalDetails ? this.modalDetails.ideal_lifecycle_stage : '',
        Deliverables: this.modalDetails?.programs_deliverablelisttables
          ? this.modalDetails.programs_deliverablelisttables.map((deliverable, index) => ` ${index + 1}. ${deliverable.deliverable}: ${deliverable.description}`)
            .join('\n')
          : 'No Deliverables',
        Criteria: this.modalDetails?.programs_shortlistingcriteriontables
          ? this.modalDetails.programs_shortlistingcriteriontables.map((criteria, index) => `${index + 1}. ${criteria.criteria}: ${criteria.description}`)
            .join('\n')
          : 'No Shortlisting Criteria',
      }]
    },
  },
}
</script>
