<template>
  <b-card
    :key="program.id"
    class="ecommerce-card border"
    header-tag="header"
    no-body
    tag="article"
  >
    <Promised
      v-if="program.image && program.image !== 'null'"
      :promise="getLinkFromS3(program.image)"
    >
      <template #pending>
        <b-card-img
          class="card-img"
          src="https://picsum.photos/600/300/?image=25"
        />
      </template>
      <template #default="data">
        <b-aspect
          :style="`background: url(${data}) center center / cover no-repeat`"
          aspect="2"
        />
      </template>
      <template #rejected>
        <b-card-img
          class="card-img"
          src="https://picsum.photos/600/300/?image=25"
        />
      </template>
    </Promised>
    <b-img
      v-else
      alt="image"
      class="rounded"
      img-top
      src="https://picsum.photos/600/300/?image=25"
      style="width: 100%; position: relative;"
    />
    <b-badge
      v-if="program.industry"
      style="position: absolute; top: 10px; right: 10px;color: black;"
      variant="light"
    >
      {{ program.industry }}
    </b-badge>
    <b-badge
      style="position: absolute; top: 10px; left: 10px;color: black;"
      variant="light"
    >
      {{ type() }}
    </b-badge>
    <b-card-body>
      <div class="d-flex align-items-center ">
        <b-img
          alt="Rounded image"
          rounded
          src="https://shorturl.at/kxFUX"
          style="height: 30px; width: 30px; margin-right: 10px;"
        />
        <b-card-text><strong>{{ program.users_organizationtable.title }}</strong></b-card-text>
      </div>

      <div class="mt-2 mb-1">
        <h6 class="text-capitalize">
          {{ program.type }}
        </h6>
        <b-card-title>
          <div class="d-flex justify-content-between align-items-center">
            <span>
              {{ program.title }}
            </span>
            <b-button
              v-if="tab !== 'invitation' && tab !== 'dashboard'"
              class="btn btn-wishlist btn-light rounded mr-1"
              variant="light-info"
              @click="showProgramDetails(program)"
            >
              View Details
            </b-button>
          </div>
        </b-card-title>
        <b-card-text>
          {{ program.description }}
        </b-card-text>
        <div class="d-flex align-items-baseline justify-content-between">
          <div class="d-flex align-items-baseline  mb-2">
            <feather-icon
              class="mb-25 mr-1"
              icon="CalendarIcon"
              size="18"
            />

            <b-card-text>
              <strong>{{
                program.begin_date
                  ? $moment(program.begin_date)
                    .format('MMM DD, YYYY')
                  : 'Date not available'
              }}</strong>
            </b-card-text>
          </div>
          <b-badge class="badge badge-light-success">
            {{ program.delivery_mode || 'Online' }}
          </b-badge>
        </div>

        <b-card-text><strong>Duration : {{ (program.duration) }}</strong></b-card-text>
        <b-card-text><strong>Capacity of Program : {{ (program.capacity) }}</strong></b-card-text>
      </div>
      <div
        v-if="tab ==='invitation'"
        class="item-options text-center d-flex "
      >
        <b-link
          class="btn btn-wishlist btn-light rounded mr-1"
          variant="light"
          @click="showProgramDetails(program)"
        >
          <span>View Details</span>
        </b-link>
        <b-button
          v-if="program.users_associationtables && program.users_associationtables.length > 0 && program.users_associationtables[0].status === 'Accept'"
          :disabled="true"
          class="btn btn-success btn-cart rounded ml-1"
        >
          <span>Accepted</span>
        </b-button>
        <b-button
          v-else-if="program.users_associationtables && program.users_associationtables.length > 0 && program.users_associationtables[0].status === 'Reject'"
          :disabled="true"
          class="btn btn-danger btn-cart rounded ml-1"
        >
          <span>Rejected</span>
        </b-button>
        <b-link
          v-else
          class="btn btn-primary btn-cart rounded ml-1"
          @click="showRespondModal(program)"
        >
          <span>Respond</span>
        </b-link>
      </div>
      <div
        v-else
        class="item-options text-center d-flex justify-content-between "
      >
        <b-link
          v-if="program.programs_partnerstables && program.programs_partnerstables.length > 0"
          :to="{ name: `mentor-${opportunitiesType}-rounds`, params: { id: program.id, pid: program.programs_partnerstables[0].id } }"
          class="btn btn-wishlist btn-light rounded mr-1"
          variant="light"
        >
          <span>Applications</span>
        </b-link>
        <b-link
          v-if="program.programs_partnerstables && program.programs_partnerstables.length > 0"
          :to="{name: `mentor-${opportunitiesType}-select`, params: {id: program.id, pid: program.programs_partnerstables[0].id}}"
          class="btn btn-primary btn-cart rounded  ml-1"
        >
          <span>Manage</span>
        </b-link>
      </div>

    </b-card-body>
    <!-- Product Actions -->
    <b-card-footer
      v-if="program.deliverable && program.deliverable !== 'null'"
      class="text-center p-1 pb-0 mt-1"
    >
      <strong> {{ program.deliverable }} </strong>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BAspect,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardImg,
  BCardText,
  BCardTitle,
  BImg,
  BLink,
} from 'bootstrap-vue'
import { Promised } from 'vue-promised'

export default {
  components: {
    BCard,
    BCardImg,
    BImg,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardText,
    BButton,
    BLink,
    BCardFooter,
    BAspect,
    Promised,
  },
  props: {
    program: {
      type: Object,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
    showProgramDetails: {
      type: Function,
      default: () => {
      },
    },
    showRespondModal: {
      type: Function,
      default: () => {
      },
    },
    opportunitiesType: {
      type: String,
      default: 'programs',
    },
  },
  methods: {
    type() {
      const oppType = this.opportunitiesType
      if (oppType === 'competitions') {
        return 'C'
      }
      if (oppType === 'events') {
        return 'E'
      }
      return 'P'
    },
  },
}
</script>
